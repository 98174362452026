import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import Button from 'shopper/components/Button';

import { toLandingPageLink, toSubcategoryLink } from 'lib/links';

import SidebarBox from './SidebarBox';

import LANDINGS from 'constants/landings';

const BLACK_FRIDAY_LIST = [
  { name: 'Celular na BF', slug: 'celular' },
  { name: 'Geladeira na BF', slug: 'geladeira' },
  { name: 'Cadeira Gamer na BF', slug: 'cadeira-gamer' },
  { name: 'Tv na BF', slug: 'tv' },
  { name: 'iPhone na BF', slug: 'iphone' },
  { name: 'Playstation 4 na BF', slug: 'ps4' },
  { name: 'Playstation 5 na BF', slug: 'ps5' },
  { name: 'Air Fryer na BF', slug: 'air-fryer' },
  { name: 'Notebook na BF', slug: 'notebook' },
  { name: 'Xbox na BF', slug: 'xbox' },
  { name: 'Celular', slug: 'celular' },
];

const OFFERS_LIST = [
  { name: 'Celular', slug: 'android' },
  { name: 'Geladeira', slug: 'geladeiras' },
  { name: 'Cadeira Gamer', slug: 'cadeira-gamer' },
  { name: 'Tv', slug: 'tv' },
  { name: 'iPhone', slug: 'iphone' },
  { name: 'Playstation 4', slug: 'playstation-4' },
  { name: 'Playstation 5', slug: 'playstation-5' },
  { name: 'Air Fryer', slug: 'fritadeira-eletrica' },
  { name: 'Notebook', slug: 'notebooks' },
  { name: 'Xbox', slug: 'xbox-360' },
];

const OffersSidebarBox = forwardRef(
  ({ className, isBlackFriday = false, ...rest }, ref) => {
    const list = isBlackFriday ? BLACK_FRIDAY_LIST : OFFERS_LIST;
    const title = isBlackFriday
      ? 'Black Friday é no Promobit'
      : 'Ofertas é no Promobit';

    return (
      <SidebarBox
        ref={ref}
        className={twMerge('pb-2', className)}
        title={title}
        {...rest}
      >
        <div className="flex flex-wrap">
          {list.map(({ name, slug }, index) => (
            <NextLink
              key={index}
              href={
                isBlackFriday
                  ? toLandingPageLink(slug, LANDINGS.TYPES.BLACK_FRIDAY)
                  : toSubcategoryLink(slug)
              }
              prefetch={false}
              passHref
            >
              <Button
                as="a"
                className="mb-2 mr-2"
                data-test-selector="goto-subcategory-offers"
                size="size3"
                type="secondary-stroke"
              >
                {name}
              </Button>
            </NextLink>
          ))}
        </div>
      </SidebarBox>
    );
  }
);

OffersSidebarBox.displayName = 'OffersSidebarBox';

OffersSidebarBox.propTypes = {
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isBlackFriday: PropTypes.bool,
};

export default OffersSidebarBox;
